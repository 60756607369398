import { Alert, AlertTitle, CircularProgress, Container } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { SERMON_PAGE_TITLE } from './strings'
import 'react-h5-audio-player/lib/styles.css'
import SectionBanner from '../common/SectionBanner'
import { callGetSermonSeriesList } from '../client/sermon-client'
import SermonSeriesSearch from './search/SermonSeriesSearch'
import SermonSeries from '../client/types/sermon-series'
import SermonList from './SermonList'
import customTheme from '../theme'
import SearchType from './search/SearchType'
import SermonTitleSearch from './search/SermonTitleSearch'
import SermonSearchFilter from './search/SermonSearchFilter'

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  ${customTheme.breakpoints.down('md')} {
    padding: 0 0.5%;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  margin-bottom: 30px;

  ${customTheme.breakpoints.down('md')} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const StyledSermonSearchFilter = styled(SermonSearchFilter)`
  width: 15%;
  margin: 0 15px 0 0;

  ${customTheme.breakpoints.down('md')} {
    width: 100%;

    margin: 0 0 15px 0;
  }
`

const SearchField = styled.div`
  flex: 1;
  ${customTheme.breakpoints.down('md')} {
    width: 100%;
  }
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`

const SermonSection: FC = () => {
  const [isSermonSeriesLoading, setIsSermonSeriesLoading] =
    useState<boolean>(false)
  const [sermonSeriesList, setSermonSeriesList] = useState<SermonSeries[]>([])

  const [searchType, setSearchType] = useState<string>()

  // Determine sermon series filter by the query parameters.
  const [searchParams, setSearchParams] = useSearchParams()
  const sermonSeriesParam = searchParams.get('sermonSeries') || undefined

  // Load all sermon series to populate search dropdown and for the sermon rows.
  useEffect(() => {
    const getSermonSeries = async () => {
      const response = await callGetSermonSeriesList()
      setSermonSeriesList(response.data.sermonSeries)
      setIsSermonSeriesLoading(false)
    }

    setIsSermonSeriesLoading(true)
    getSermonSeries()
  }, [])

  const tagIdToSermonSeries = sermonSeriesList.reduce(
    (newObject: { [key: string]: SermonSeries }, sermonSeries) => {
      newObject[sermonSeries.tagId] = sermonSeries
      return newObject
    },
    {}
  )

  if (isSermonSeriesLoading) {
    return (
      <SectionContainer>
        <CircularProgress color="primary" />
      </SectionContainer>
    )
  }

  return (
    <SectionContainer>
      <SearchContainer>
        <StyledSermonSearchFilter onSermonFilterChange={setSearchType} />
        <SearchField>
          {searchType === SearchType.Series ? (
            <SermonSeriesSearch
              sermonSeriesList={sermonSeriesList.sort((a, b) =>
                b.startDate < a.startDate ? -1 : 0
              )}
              handleChange={(sermonSeriesId?: string) => {
                setSearchParams(
                  sermonSeriesId ? { sermonSeries: sermonSeriesId } : {}
                )
              }}
              placeholderText={tagIdToSermonSeries[sermonSeriesParam!!]?.title}
            />
          ) : (
            <SermonTitleSearch
              handleFormSubmit={(searchText?: string) => {
                setSearchParams(searchText ? { match: searchText } : {})
              }}
            />
          )}
        </SearchField>
      </SearchContainer>
      <ListContainer>
        <SermonList
          searchParams={searchParams}
          idToSermonSeriesMap={tagIdToSermonSeries}
        />
      </ListContainer>
    </SectionContainer>
  )
}

const SermonMigrationBanner: FC = () => {
  return (
    <Alert severity="warning" sx={{ margin: '0 0 24px 0' }}>
      <AlertTitle>Sermon Page Under Construction</AlertTitle>
      We are currently updating our sermon page to better serve you!
      <br />
      In the meantime, check out our latest sermons on{' '}
      <a
        href="https://www.youtube.com/c/CrossLifeBibleChurch"
        target="_blank"
        rel="noreferrer"
      >
        YouTube.
      </a>
      <br />
      We apologize for any inconvenience and appreciate your patience.
    </Alert>
  )
}

const SermonView: FC = () => {
  return (
    <>
      <SectionBanner title={SERMON_PAGE_TITLE} />
      <Container maxWidth="md" sx={{ mt: '4%' }}>
        <SermonMigrationBanner />
        <SermonSection />
      </Container>
    </>
  )
}

export default SermonView
